$base-font-size:   16px !default;
$small-font-size:  $base-font-size * 0.875 !default;
$grey-color:       #828282 !default;.credits, .footnotes {
  font-size: $small-font-size;
}

.credits {
  color: $grey-color;
}

.small {
  max-width: 80%;
}

.mini {
  max-width: 30%;
}

.left {
  float: left;
  margin-right: 1.5em;
}

.right {
  float: right;
  margin-left: 1.5em;
}

.left, .right {
  margin-bottom: 0.8em;
}

.center {
  display: block;
  margin: 0 auto 1.5em;
}

.comments-explain {
  opacity: 70%;
}

.comment-about {
  text-align: right;
  opacity: 50%;
}

.comment-author {
  font-weight: bold;
}

ul.comments > li.comment {
  list-style-type: none;
}
